// Event Url (Regarding events)

export const EVENT_URL = "/event/url/"; //(Event details -> /event/url/{event-url} and Event Ticket Details -> /event/url/{event-url}/tickets)
export const EVENT_WAITLIST = "/event/url/waitlist/"; //(EVENT_WAITLIST + {event-url}?booking_id={booking_id})
export const EVENT_VALIDATE = "/event/url/validate"; // Validate if event URL is available\
export const LITE_PLAN = "Lite";
export const SILVER_PLAN = "Silver";
export const GOLD_PLAN = "Gold";
export const ENTITY_TYPES = {
    SPONSORS: 1,
    PARTNERS: 2,
    EXHIBITORS: 3,
};

