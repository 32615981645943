import React, { useState } from "react";

const TaxForm = ({
  setTaxPercentageOnCalculator,
  participantKonfhubFee,
  organiserKonfhubFee,
  setParticipantKonfhubFee,
  organiserTaxCharge,
  participantTaxCharge,
  setOrganiserKonfhubFee,
  setOpenTotal,
  taxHandle,
  isTaxChecked,
  setParticipantTaxCharge,
  setOrganiserTaxCharge,
}) => {
  return (
    <div>
      <div className="tax-form font-figtree">
        {/* Checkbox for tax collection */}
        <div className="tax-collection">
          <label>
            <input
              type="checkbox"
              className="block tax-checkbox"
              checked={isTaxChecked}
              onChange={(e) => {
                taxHandle(e);
                setOpenTotal(false);
              }}
            />
            Do you want to collect tax?
          </label>
          {isTaxChecked && (
            <div className="tax-percentage ">
              <label>Tax percentage</label>
              <span className="percent-cont d-flex justify-space-between ">
              <input
                type="number"
                id="taxPercentage"
                max={99}
                name="taxPercentage"
                className="taxPercentage-input no-border"
                defaultValue={18}
                onChange={(e) => setTaxPercentageOnCalculator(e.target.value)}
              />
              <span className="me-3">|</span>
              <span>%</span>
               </span>
            </div>
          )}
        </div>

        {/* Payment gateway fee payer */}
        <div className="fee-payer">
          <label>Who will pay the payment gateway fee?</label>
          <div className="fee-options">
            <button
            data-type="Organizer"
              className={organiserKonfhubFee == true ? "selected" : ""}
              onClick={() => {
                setOrganiserKonfhubFee(true);
                setParticipantKonfhubFee(false);
              }}
            >
              Organizer
            </button>
            <button
             data-type="participants"
              className={participantKonfhubFee === true ? "selected" : ""}
              onClick={() => {
                setParticipantKonfhubFee(true);
                setOrganiserKonfhubFee(false);
              }}
            >
              Participants
            </button>
          </div>
        </div>

        {/* Tax payer - only show if collectTax is true */}
        {isTaxChecked && (
          <div className="tax-payer">
            <label>Who will pay the tax charges?</label>
            <div className="fee-options">
              <button
              data-type="Organizer"
                className={organiserTaxCharge == true ? "selected" : ""}
                onClick={() => {
                  setOrganiserTaxCharge(true);
                  setParticipantTaxCharge(false);
                }}
              >
                Organizer
              </button>
              <button
              data-type="participants"
                className={participantTaxCharge == true ? "selected" : ""}
                onClick={() => {
                  setOrganiserTaxCharge(false);
                  setParticipantTaxCharge(true);
                }}
              >
                Participants
              </button>
            </div>
          </div>
        )}
      </div>
      <style>
        {`
          .tax-form {
            color: #002E6E;
          }
          input[type="checkbox"] {
                display: block !important;
            }

          .tax-collection {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .percent-cont{
            border:1px solid #475467;
            //padding:5px 9px;
            border-radius:4px;
            padding:2px 4px;
            display:flex;
            align-items:center;
            justify-content: space-around;
          }

          .tax-collection label {
            margin-right: 15px;
            display:flex;
            align-items:center;
            gap:10px;
          }
          .tax-checkbox{
                height:15px;
                width:15px;
          }

          .tax-percentage {
            display: flex;
            align-items: center;
          }

          .tax-percentage input {
            width: 50px;
            //padding: 3px 9px;
            border: 0;
            border-radius: 5px;
            margin-right: 5px;
            color: #002E6E;
          }

          .fee-payer, .tax-payer {
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .fee-options {
            display: flex;
            margin-top: 10px;
            border: 1px solid #002E6E;
            border-radius: 4px;
          }

          .fee-options button {
           //border: 2px solid #E1F0FF;
            background-color: white;
            color: #002E6E;
            cursor: pointer;
            border:0;
            border-radius: 3px;
            padding: 3px 12px;
          
          }

          .fee-options button.selected {
            background-color: #002E6E;
            color: white;
            border:0;
             border-radius:3px;
               border-top-right-radius: 0;
                   border-bottom-right-radius: 0;
          }

          .fee-options  button[data-type="participants"].selected {
            background-color: #002E6E;
            color: white;
            border:0;
             border-radius:3px;
               border-top-left-radius: 0;
                border-bottom-left-radius: 0;
          }
                    button:focus{
            box-shadow:0 0 0 !important;
          }
          input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Remove the border for the input field */
        .no-border {
            border: none;
            outline: none; /* Also removes the focus outline */
           // background-color: #e8f0fe; /* Optional: matching background color for a clean look */
           // padding: 5px; /* Optional: adjust padding for better spacing */
        }

        /* If you want to keep the focus outline visible when the input is focused */
        .no-border:focus {
            outline: none;
        }


        /* For Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }

        @media (max-width: 576px) {
              .tax-collection ,.fee-payer,.tax-payer{
              display:flex !important;
              flex-direction:column !important;
              align-items:flex-start !important;
              gap:7px;
            }
        }
        `}
      </style>
    </div>
  );
};

export default TaxForm;
