import React, { useEffect, useState } from 'react';
import revampData from '../../data/revampData.json';
import { theme, websiteColors } from "../../themes/Theme.js";
import axios from 'axios';
import Link from 'next/link.js';
import EventPricingCalculator from '../Pricing/EventPricingCalculator.jsx';
import { hostEventUrl } from '../../config/config.js';
import PricingPageFeatureCard from '../Pricing/PricingPageFeatureCard.jsx';
import SupportedCurrencies from '../Pricing/SupportedCurrencies.jsx';
import { useRouter } from 'next/router';

const HomePricingPlans = ({ ishomePage }) => {
  const [activeTab, setActiveTab] = useState('india');
  const intros = revampData.data.listPricingSectionIntros.data[0];
  const planTermsData = revampData.data.listPlanTerms.data[0];
  const listPricingInfos = revampData.data.listPricingInfos.data;
  const router = useRouter();
  const plans =
    activeTab === 'india'
      ? revampData.data.listPricingPlanCards.data
      : revampData.data.listPricingPlanForRestOfWorld.data;

  // Custom order array for sorting
  const order = ["Freemium", "Lite", "Silver", "Gold"];

  // Sort the plans based on the planName
  const sortedPlans = plans.sort((a, b) => {
    return order.indexOf(a.planName) - order.indexOf(b.planName);
  });

  // Function to update the tab and URL hash
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    router.push(`#${tab}`, undefined, { shallow: true });
  };

  // Read the hash from the URL on the initial load
  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash === 'rest-of-world') {
      setActiveTab('rest-of-world');
    } else {
      setActiveTab('india');
    }
  }, []);

  

  return (
    <div className="px-sm-5 px-3 font-figtree pb-lg-5 pb-4">

      {ishomePage !== 'pricing-page' && <div className=" text-center py-5 m-auto" style={{ maxWidth: '700px' }}>
        <h6 className="text-uppercase heading-label mb-2">{intros.featuresData.section}</h6>
        <h1 className="display-5 main-heading ">{intros.featuresData.title}</h1>
        <p className="lead  main-sub-heading mt-3">{intros.featuresData.subDescription}</p>
      </div>
      }
      <div className='d-flex flex-column w-100 m-auto mb-3 mt-3'>

        {/* <p className="lead  main-sub-heading mt-3">{intros.featuresData.subDescription}</p> */}
      </div>
      <div className='d-flex flex-column w-100 m-auto'>
        <div className="tab-buttons d-flex gap-1 mb-4 m-auto ">
          <button
            className={` tab-button ${activeTab === 'india' ? 'active' : ''}`}
            onClick={() => handleTabChange('india')}
          >
            India
          </button>
          <button
            className={` tab-button ${activeTab === 'rest-of-world' ? 'active' : ''}`}
            onClick={() => handleTabChange('rest-of-world')}
          >
            Rest of the World
          </button>
        </div>

        <div className="row d-flex flex-sm-row mt-4">
          {sortedPlans.map((plan) => (
            <div key={plan.id} className="col-lg-6 col-xl-3 col-md-6 col-sm-12 mb-4">
              <div className={`pricing-card h-100  ${plan.defaultPlan ? 'default-plan' : ''}`}>
                {/* <div className='d-flex flex-column justify-content-between top-container p-4 '> */}
                <div className={`d-flex flex-column justify-content-between p-4 ${activeTab === 'india' ? 'top-container' : 'bottom-border'}`}>                 <div>


                  <div className='d-flex justify-content-between w-100'> <h4 className="plan-name">{plan.planName}</h4>
                    {plan?.defaultPlan ? <span className='default-plan1'>Default Plan</span> : ''}
                    {plan?.popular ? <span className='default-plan1'>Popular</span> : ''}

                  </div>
                  {plan.price !== "$2999" ? <h2 className="plan-pricing-amount">{plan.price} <span>{plan.gst}</span></h2> : <h2 className="plan-pricing-amount">Custom</h2>}
                  <p className="text-muted">{plan.additionalInfo}</p>  </div>
                  {plan.price !== "$2999" ? <Link target='_blank' href={`${hostEventUrl}/create/event`}> <button className="host-btn w-100">{plan.buttonText}</button></Link> : <Link target='_blank' href="mailto:support@konfhub.com"> <button className="host-btn w-100">Contact Us</button></Link>}
                </div>

                {/* Features */}
                <div className='p-4'>
                  <div className='' >
                    <h2 className="plan-desc">{plan?.planDetails?.description}</h2>
                    <p className="plan-addInfo">{plan?.planDetails?.additionalInfo}</p>
                  </div>
                  <ul className="list-unstyled mt-4  ">
                    {Object.values(plan.features).map((feature, index) => (
                      <li key={index} className="d-flex align-items-start mb-2 gap-2  ">
                        {feature && <> <img src="/img/tick.svg" className='tick-icon' alt="" />
                          <span className='plan-listitem'>{feature}</span></>}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {
        ishomePage !== 'pricing-page' && activeTab === 'india' && (
          <div className="additional-info my-5 py-2 text-center">
            <p className="text-muted">
              {planTermsData.gstNote}
              <br />
              {planTermsData.attendeeNote}
            </p>
          </div>
        )
      }

      <div className="custom-plan-card d-flex flex-wrap justify-content-between align-items-center mb-4  p-3 py-2 py-xl-3 mt-2">
        <div className="custom-plan-text mt-3">
          <h5 className="fw-bold text-md-center text-lg-center text-start text-xl-start text-pricing">Custom</h5>
          <p className="text-muted text-sm-center text-start mb-0">
            Can't find a plan that fits your needs? Contact our team to create a custom package tailored just for you.
          </p>
        </div>
        <div className='mt-auto ml-0 mauto mt-md-4 mt-2 mt-xl-0 w-full '>
          <Link href="mailto:support@konfhub.com"> <button className="get-started-btn text-nowrap m-auto">
            Contact Us
          </button></Link>
        </div>

      </div>
      {/* {ishomePage == 'pricing-page' &&
      <div className="txt-container text-center my-5">
      <p className="transparent-pricing mb-4">
        Transparent pricing: No hidden fees, subscription fees or any other transaction charges!
      </p>
      <p className='mb-5'>
        Directly receive ticket sale amount to your connected Payment Gateway account (minus PG charges). PG charges are not included in the pricing plan.
        <br/>
        <small>*US$ 1 per additional registration beyond the plan limit</small>
      </p>
      <a href="/" className="feature-comparison-link ">
        See our complete feature comparison
      </a>
    </div>
    } */}
      {
        ishomePage === 'pricing-page' && (
          <div className="txt-container text-center my-5">
            {activeTab === 'india' ? (
              <>
                <p className="transparent-pricing mb-1">
                  {listPricingInfos[0].pricingInfo.india.mainDescription}
                </p>
                <p className="transparent-pricing mb-4">
                  {listPricingInfos[0].pricingInfo.india.additionalDescription}
                </p>
                <p className='mb-1' style={{ color: "#525252", fontSize: "18px" }}>
                  {listPricingInfos[0].pricingInfo.india.gstNote}

                </p>
                <p className='mb-5' style={{ color: "#525252", fontSize: "18px" }}>
                  {listPricingInfos[0].pricingInfo.india.attendeeNote}
                </p>
                {/* <a href={listPricingInfos[0].pricingInfo.india.cta.link} className="feature-comparison-link">
          {listPricingInfos[0].pricingInfo.india.cta.text}
        </a> */}
              </>
            ) : (
              <>
                <p className="transparent-pricing mb-4">
                  {listPricingInfos[0].pricingInfo.restOfTheWorld.mainDescription}
                </p>
                <p className='mb-1' style={{ color: "#525252", fontSize: "18px" }}>
                  {listPricingInfos[0].pricingInfo.restOfTheWorld.gstNote}
                </p>
                <p className='mb-4' style={{ color: "#525252", fontSize: "18px" }}>
                  {listPricingInfos[0].pricingInfo.restOfTheWorld.attendeeNote}
                </p>
                {/* <a href={listPricingInfos[0].pricingInfo.restOfTheWorld.cta.link} className="feature-comparison-link">
          {listPricingInfos[0].pricingInfo.restOfTheWorld.cta.text}
        </a> */}
              </>
            )}
          </div>
        )
      }

      {
        ishomePage === 'pricing-page' && (
          <div>
            {activeTab === 'india' && <PricingPageFeatureCard />}
          </div>
        )
      }
      {
        ishomePage === 'pricing-page' && (
          <div>
            {activeTab === 'india' && <EventPricingCalculator />}
          </div>
        )
      }
      {
        ishomePage === 'pricing-page' && (
          <div>
            {activeTab != 'india' && <SupportedCurrencies />}
          </div>
        )
      }

      <style jsx>{`
        .tab-buttons .btn {
          padding: 2px 25px !important;
          font-size: 16px;
          font-weight:600;
        }
        .pricing-card {
          border: 1px solid #e0e0e0;
          border-radius: 10px;
          background-color: #fff;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease;
        }
        .pricing-card:hover {
          transform: translateY(-5px);
          box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
        }
        .pricing-amount {
          font-size: 2.5rem;
          color: #333;
        }
        .tick-icon{
          max-width: 24px !important;
          max-height: 24px !important;
        }
        .default-plan {
          border: 2px solid #007bff;
          background-color: #f5faff;
        }
        .default-plan .btn-primary {
          background-color: #007bff;
        }
        .default-plan1 {
          border: 1px solid ${websiteColors[theme].primaryWebsiteTheme};
          background-color: #E1F0FF;
          border-radius:5px;
          font-size:14px;
          text-align:center;
          font-weight:500;
          color:${websiteColors[theme].textParagraph};
          height: 28px; 
          line-height: 20px;
          border-radius: 9999px; 
          white-space:nowrap;
          padding: 4px 10px; 
        }
        .btn-primary {
          margin-top: 15px;
          font-weight: bold;
          padding: 10px;
        }

        .heading-label{
          color:${websiteColors[theme].primaryWebsiteTheme};
          font-size:16px;
          font-weight:600;
          line-height:24px;
        }
        .top-container{
          min-height:270px;
          margin-bottom:10px;
          border-bottom:1px solid #EAECF0;
        }
        .main-sub-heading{
          color:${websiteColors[theme].textSecondary};
          font-size:24px;
          font-weight:400;
          line-height:30px;
        }
        .main-heading{
          color:#001A47;
          font-size:48px;
          font-weight:600;
          line-height:57.6px;
          letter-spacing:-2px;
        }
        .tab-buttons {
          display: inline-flex;
          background-color: #E1F0FF;
         padding: 3px 2px;
          border-radius: 4px;
        }

        .tab-button {
          background: none;
          border: none;
          padding: 3px 20px;
          font-size: 16px;
          color: #001234;
          cursor: pointer;
          transition: background-color 0.3s ease;
          border-radius: 3px;
        }

        .tab-button.active {
          background-color: white;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
          font-weight: 600;
        }
        .bottom-border{
          border-bottom:1px solid #EAECF0;
        }
        .tab-button:not(.active):hover {
          background-color: #fff;
        }
        .plan-listitem{
          font-weight:400;
          font-size:16px;
          line-height:24px;
        }
        button .btn{
          box-shadow:0px !important;
        }
        .host-btn{
          box-shadow: 0px 1px 2px rgba(16, 24, 40, .05);
          font-weight: 600;
          background-color:${websiteColors[theme].primaryWebsiteTheme};
          color:#fff;
          padding:5px 40px;
          border-radius:4px;
          font-size:16px;
        }
        .plan-addInfo{
          font-size:16px;
          font-weight:600;
          line-height:24px;
          color:#525252;
        }
        .plan-desc{
          font-size:16px;
          font-weight:700;
          line-height:24px;
          color:${websiteColors[theme].textParagraph};
        }
        .plan-name{
          font-size:18px;
          font-weight:700;
          line-height:28px;
          color:#525252;
        }
        .plan-pricing-amount{
          font-size:45px;
          font-weight:700;
          line-height:72px;
          color:${websiteColors[theme].textParagraph};
        }
        button:focus, select:focus, textarea:focus {
            box-shadow:0 0 0 !important;
        }
        .plan-pricing-amount span{
          font-size:16px;
          font-weight:500;
          line-height:24px;
          color:#525252;
        }
        .container{
          max-width:1200px !important;
          width:100%;
        }
        .get-started-btn {
          font-weight:600;
        }
        .custom-plan-card{
          border-radius:10px;
          padding-left:32px !important ;
          padding-right:32px !important ;
        }
        @media (max-width: 1111px) {
         .mauto{
          margin:15px auto !important;
          display:flex; 
         }
        }
        @media (max-width: 576px) {
         .main-heading{
          font-size:32px;
          font-weight:600;
          line-height:38.4px;
        }
        .get-started-btn {
          width:100%;
        }
        .w-full{
          width:100%;
        }
          .feature-comparison-link{
            font-size: 20px !important;
          }
         
        }
        .custom-plan-card {
          border: 1px solid #e0e0e0;
          border-radius: 10px;
          background-color: #fff;
        }
        .get-started-btn {
          background-color: ${websiteColors[theme].primaryWebsiteTheme};
          color: #fff;
          border: none;
          border-radius: 5px;
          padding: 10px 20px;
          cursor: pointer;
        }
        .get-started-btn:hover {
          background-color: #003a8a;
        }
        .text-muted {
          color: #525252 !important;
        }
        .txt-container{
          max-width:878px;
          margin:auto;
        }
        .transparent-pricing{
          color:${websiteColors[theme].primaryWebsiteTheme};
          font-size:18px;
          font-weight:500;
        }
        .transparent-pricing p , small{
          color:#525252;
          font-size:18px;
          font-weight:400;
          line-height:30px;
        }
        .feature-comparison-link{
          font-weight:500;
          font-size:24px;
          color:${websiteColors[theme].primaryWebsiteTheme};
          line-height:24px;
        }
      `}</style>
    </div >
  );
};

export default HomePricingPlans;