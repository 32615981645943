import React from "react";
import Marquee from "react-fast-marquee";
import revampData from '../../data/revampData.json';

const companies = revampData.data.listTrustedCompanies.data;

const CompanyCarousel = ({ isPricingPage }) => {
  return (
    <div className="my-4 font-figtree">
      {isPricingPage && (
        <p className="mb-4 w-100 w-full text-uppercase fw-semibold font-sm m-auto text-center" style={{ color: '#002E6E' ,fontFamily:"Figtree"}}>
          Trusted by event teams from
        </p>
      )}
      <div className={`company-carousel ${isPricingPage ? 'mb-5' : 'mb-1'} `}>
        <Marquee
          speed={35}
          gradient={false}
          pauseOnHover={true}
          className="company-marquee"
        >
          {companies.map((company) => (
            <div key={company.id} className="company-item mx-3">
              <img
                src={company.image}
                alt={company.description}
                className="company-image partner-logo"
              />
            </div>
          ))}
        </Marquee>
        <style jsx>{`
          .company-carousel {
            max-width: 900px;
            width: 100%;
            margin: auto;
          }
          .company-image {
            max-width: 150px;
            max-height: 90px;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          .partner-logo {
            filter: grayscale(100%);
            transition: filter 0.3s ease;
          }

          .partner-logo:hover {
            filter: grayscale(0%);
          }
        `}</style>
      </div>
    </div>
  );
};

export default CompanyCarousel;