import React from "react";
import FeatureCard from "../../revampComponents/FeaturesCard";
import revampData from "../../data/revampData.json";

const PricingPageFeatureCard = () => {
    const featuresCards = revampData.data.listPricingPageFeatureCards.data;

    return (
        <div className="font-figtree">
            <FeatureCard featuresData={featuresCards} Swipcard={true} />
        </div>
    );
};

export default PricingPageFeatureCard;